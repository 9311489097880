// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-back-button {
  display: block;
}
ion-header ion-title {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  padding: 0 50px;
}

.selected-car-info-section {
  padding: 10px;
}
.selected-car-info-section .checkbox-item {
  align-items: flex-start;
}
.selected-car-info-section .checkbox-item ion-checkbox {
  margin-right: 10px;
}

ion-modal.modal-calendar {
  --height: auto;
  --border-radius: 10px;
  --width: 300px;
  margin: auto;
}
ion-modal.modal-calendar::part(backdrop) {
  --backdrop-opacity: 0.3;
}

ion-popover.calendar-popover {
  --width: auto;
}

ion-content .request-confirm-message {
  white-space: pre-wrap;
}

ion-footer {
  --background: var(--ion-color-light-warm);
}
ion-footer ion-toolbar {
  --background: var(--ion-color-light-warm);
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
ion-footer ion-toolbar .submit-button {
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
  margin: 10px 0;
}

.calendar-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  background-color: var(--ion-backdrop-color, #000);
  opacity: var(--ion-backdrop-opacity, 0.32);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
